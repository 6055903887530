<template>
  <!--
    The view for the NotifyWizardCustom-component.
    Used to send custom message to people.
  -->
  <Portlet
    title="Custom Message"
    icon="envelope"
  >
    <NotifyWizardCustom
      :installation-id="installationId"
      :recipient-id="recipientId"
    />
  </Portlet>
</template>

<script>
export default {
  name: "CustomNotifyMessage",
  components: {
    NotifyWizardCustom: () => import('@/components/NotifyWizard/NotifyWizardCustom.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    recipientId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('notifyCustomWizard')
    }
  }
}
</script>
